<template>
  <div>
    <NavigationBar showBackBtn titel="Details" isPreview></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <!-- <Subheader icon="mdi-calendar" title="Allgemein"></Subheader> -->
              <div class="mt-7">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <report-details-card
                      :loading="helpers.dataIsLoading"
                      :report="report"
                    ></report-details-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="card-heading, list-item, divider, article, divider, actions"
                    ></v-skeleton-loader>
                    <v-row v-else class="match-height">
                      <v-col cols="12" md="6" sm="6">
                        <statistics-single-card
                          title="Dauer"
                          color="blue-grey"
                          icon="mdi-timer-sand-complete"
                          value="2h 15min"
                        ></statistics-single-card>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <statistics-single-card
                          title="Titel"
                          color="blue-grey"
                          icon="mdi-circle"
                          value="XXX"
                        ></statistics-single-card>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <statistics-single-card
                          :title="
                            report.status === 'finished'
                              ? 'Abgeschlossen am'
                              : 'Fällig bis'
                          "
                          :color="
                            report.status === 'finished' ? 'green' : 'red'
                          "
                          :icon="
                            report.status === 'finished'
                              ? 'mdi-archive-lock-outline'
                              : 'mdi-calendar-alert'
                          "
                          value="XX.XX.2024"
                        ></statistics-single-card>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <statistics-single-card
                          :title="
                            report.status === 'finished'
                              ? 'Abgeschlossen von'
                              : 'Berichtspflichtig'
                          "
                          :color="
                            report.status === 'finished' ? 'green' : 'red'
                          "
                          :icon="
                            report.status === 'finished'
                              ? 'mdi-account-lock'
                              : 'mdi-account-alert'
                          "
                          value="Maximilian Schmidt"
                        ></statistics-single-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ReportDetailsCard from "@/components/duty-management/ReportDetailsCard.vue";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
export default {
  name: "duty-management-reports-details",
  components: {
    NavigationBar,
    ReportDetailsCard,
    StatisticsSingleCard,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
      report: {
        title: "Abenddienst (5h)",
        type: {
          title: "Alarmbereitsschaftsdienst",
          color: "#673AB7FF",
          icon: "alarm-bell",
        },
        duration: {
          start: { timestamp: "01.12.2024 10:00" },
          end: { timestamp: "01.12.2024 12:00" },
        },

        assignedTo: { displayName: "Maximilian Schmidt" },
        dueDate: "01.12.2024",
        status: "toBeReviewed",
        nextActivity: "Endzeit erfassen", // addDetails, addEndTimestamp, review
        meta: { id: "a" },
      },
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
