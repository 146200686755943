<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title>
        <span>{{ report.title }}</span>
        <v-spacer></v-spacer>
        <report-status-chip :status="report.status"> </report-status-chip>
      </v-card-title>
      <v-card-subtitle>
        <report-type-chip :reportType="report.type" small> </report-type-chip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-start</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          <span class="mr-4">
            <!-- {{ timestampConverter(report.duration.start.timestamp) }} -->
            Uhr
          </span>
          <br class="hidden-md-and-up uppercase" />
          <v-chip
            v-if="report.duration.start.isOpen"
            :x-small="mobile"
            :small="!mobile"
            class="font-weight-medium red--text text--darken-4"
            color="red lighten-4"
            >Startzeit noch offen</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-end</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          <span class="mr-4">
            <!-- {{ timestampConverter(report.duration.end.timestamp) }} Uhr -->
          </span>
          <br class="hidden-md-and-up uppercase" />
          <v-chip
            v-if="report.duration.end.isOpen"
            :x-small="mobile"
            :small="!mobile"
            class="font-weight-medium red--text text--darken-4"
            color="red lighten-4"
            >Endzeit noch offen</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item v-if="report.assignedTo">
        <v-list-item-icon>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Berichtsverantwortlich:
          <v-chip label small class="ml-2 my-2"
            ><v-icon small left>mdi-account-circle</v-icon
            >{{ report.assignedTo.displayName }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item v-if="report.connectedEventId">
        <v-list-item-icon>
          <v-icon>mdi-list-status</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal">
          <v-btn
            small
            depressed
            :to="{
              name: 'scheduling-events-details-tab-general',
              params: { itemId: report.connectedEventId },
            }"
            ><v-icon small class="mr-1">mdi-link-variant</v-icon>Verknüpfte
            Veranstaltung öffnen</v-btn
          >
        </v-list-item-subtitle>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import ReportTypeChip from "@/components/duty-management/ReportTypeChip.vue";
import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
export default {
  name: "report-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    report: {
      type: Object,
    },
  },
  components: { ReportTypeChip, ReportStatusChip },
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
          return "cyan";
        case "#009688FF":
        case "#009688":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
          return "grey";
        default:
          return "grey";
      }
    },
    statusText(status) {
      switch (status) {
        case "planned":
          return "Geplant";
        case "postponed":
          return "Verschoben";
        case "canceled":
          return "Abgesagt";
        case "finished":
          return "Abgeschlossen";
        case "deletion started":
          return "Löschung gestartet";
        default:
          break;
      }
    },
    statusColor(status) {
      switch (status) {
        case "planned":
          return "blue lighten-4";
        case "postponed":
          return "orange lighten-4";
        case "canceled":
          return "red lighten-4";
        case "finished":
          return "green lighten-4";
        case "deletion started":
          return "red lighten-4";
        default:
          break;
      }
    },
    statusTextClass(status) {
      switch (status) {
        case "planned":
          return "font-weight-medium blue--text text--darken-4";
        case "postponed":
          return "font-weight-medium orange--text text--darken-4";
        case "canceled":
          return "font-weight-medium red--text text--darken-4";
        case "finished":
          return "font-weight-medium green--text text--darken-4";
        case "deletion started":
          return "font-weight-medium red--text text--darken-4";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
  computed: {
    attendeeItems() {
      return [];
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
