<template>
  <div>
    <!-- showDiscardBtn -->
    <NavigationBar
      titel="Dienstbericht erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <report-editor v-model="report" mode="new"></report-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORT_TYPES, CREATE_REPORT } from "@/store/action-types.js";
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";
import ReportEditor from "@/components/duty-management/ReportEditor.vue";

export default {
  name: "duty-management-reports-new",
  components: {
    NavigationBar,
    ReportEditor,
    // Subheader,
  },
  data() {
    return {
      stepper: 3,
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      report: {
        title: "",
        dutyType: {
          title: "",
          id: "",
        },
        duration: {
          start: { timestamp: null, weekday: null },
          end: { timestamp: null, isOpen: false, weekday: null },
          inHours: 0,
          inMinutes: 0,
        },
        payrollType: {
          id: "",
          title: "",
          formula: "",
        },
        reportType: {
          id: "",
          title: "",
        },
        status: "draft", // "draft", "confirmed", "locked"
        isLocked: false,
        units: {
          ids: [],
          data: [],
        },
        attendances: {
          data: [],
          personIds: [],
        },
      },
      eventData: null,
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    reportTypes() {
      return this.$store.state.dutyManagement.reportTypes;
    },
    payrollType() {
      return this.$store.state.dutyManagement.payrollTypes;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });

      if (this.$route.query.eventId) {
        this.eventData = this.getEvent(this.$route.query.eventId);
      }
      this.dutyReportId = this.$route.query.dutyReportId;
    },
    getEvent(eventId) {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(eventId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data();
          }
          return null;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return null;
        });
    },
    createItem() {
      if (this.formIsValid) {
        this.$store.dispatch(`${DUTY_MANAGEMENT}${CREATE_REPORT}`, {
          organizationId: this.$route.params.organizationId,
          //   bezeichnung: this.bezeichnung,
          //   beschreibung: this.beschreibung,
          //   mitgliederanzahl: this.mitgliederanzahl,
          //   farbe: this.farbe,
          //   kategorie: this.kategorie,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    createTeam() {},
  },
};
</script>

<style></style>
