var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"showBackBtn":"","titel":"Details","isPreview":""}}),_c('section',[[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('report-details-card',{attrs:{"loading":_vm.helpers.dataIsLoading,"report":_vm.report}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[(_vm.helpers.dataIsLoading)?_c('v-skeleton-loader',{attrs:{"elevation":"2","type":"card-heading, list-item, divider, article, divider, actions"}}):_c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('statistics-single-card',{attrs:{"title":"Dauer","color":"blue-grey","icon":"mdi-timer-sand-complete","value":"2h 15min"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('statistics-single-card',{attrs:{"title":"Titel","color":"blue-grey","icon":"mdi-circle","value":"XXX"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('statistics-single-card',{attrs:{"title":_vm.report.status === 'finished'
                            ? 'Abgeschlossen am'
                            : 'Fällig bis',"color":_vm.report.status === 'finished' ? 'green' : 'red',"icon":_vm.report.status === 'finished'
                            ? 'mdi-archive-lock-outline'
                            : 'mdi-calendar-alert',"value":"XX.XX.2024"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('statistics-single-card',{attrs:{"title":_vm.report.status === 'finished'
                            ? 'Abgeschlossen von'
                            : 'Berichtspflichtig',"color":_vm.report.status === 'finished' ? 'green' : 'red',"icon":_vm.report.status === 'finished'
                            ? 'mdi-account-lock'
                            : 'mdi-account-alert',"value":"Maximilian Schmidt"}})],1)],1)],1)],1)],1)])],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }