<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader
              icon="mdi-format-list-bulleted-type"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model.trim="reportLocal.title"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          v-model="reportLocal.dutyType"
                          :items="dutyTypes"
                          label="Dienstsart"
                          outlined
                          required
                          :rules="[rules.required]"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="reportLocal.connectedEventId"
                          label="Verknüpfte Veranstaltung"
                          readonly
                          disabled
                          outlined
                          hint="Bericht aus Veranstaltung heraus erstellen"
                          persistent-hint
                        >
                          <template v-slot:append
                            ><v-btn
                              depressed
                              small
                              @click="toggleEventSelectionDialog"
                              ><v-icon left>mdi-calendar-search</v-icon
                              >Durchsuchen
                            </v-btn></template
                          >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          v-model="reportLocal.payrollType"
                          :items="payrollTypes"
                          label="Abrechnungsart"
                          item-text="title"
                          item-description="formula"
                          item-disabled="disabled"
                          outlined
                          clearable
                          required
                          :rules="[rules.required]"
                        >
                          <template v-slot:item="data">
                            <v-list-item
                              two-line
                              :disabled="data.item.disabled"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <small>
                                    <pre>{{ data.item.formula }}</pre>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          label="Startzeit"
                          hint="HH:MM"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="reportLocal.duration.end.isOpen"
                          label="Endzeitpunkt"
                          mandatory
                          hide-details="auto"
                          row
                          class="mt-0 pt-0"
                        >
                          <v-radio
                            label="Noch nicht abgeschlossen"
                            :value="false"
                          ></v-radio>
                          <v-radio
                            label="Endzeitpunkt festlegen"
                            :value="true"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        v-if="reportLocal.duration.end.isOpen"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="reportLocal.duration.end.isOpen"
                        cols="12"
                        sm="6"
                        md="3"
                      >
                        <v-text-field
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-fire-truck" title="Einsatzmittel"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-col
                    cols="12"
                    v-for="(unit, index) in reportLocal.units.data"
                    :key="index"
                  >
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-img
                              contain
                              max-width="120px"
                              :src="unit.photoUrl.size200x200"
                            ></v-img>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-medium mb-2"
                              >{{ unit.title }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              ><v-btn-toggle mandatory dense>
                                <v-btn
                                  v-for="(
                                    crewCombination, index
                                  ) in unit.crewCombinations"
                                  :key="index"
                                  text
                                  small
                                  :value="crewCombination.id"
                                  >{{ crewCombination.title }}</v-btn
                                >
                              </v-btn-toggle></v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              depressed
                              text
                              color="error"
                              @click="deleteUnit(unit)"
                              ><v-icon left>mdi-delete</v-icon>Löschen</v-btn
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" align="center">
                    <v-menu
                      v-model="helpers.unitSelector.model"
                      :close-on-click="false"
                      :close-on-content-click="false"
                      :nudge-width="150"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" v-bind="attrs" v-on="on">
                          <v-icon left>mdi-plus</v-icon>Einsatzmittel hinzufügen
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form
                          @submit.prevent="addUnit"
                          ref="unitSelector"
                          v-model="helpers.unitSelector.isValid"
                        >
                          <v-list>
                            <v-list-item>
                              <v-select
                                v-model="helpers.unitSelector.nextUnit"
                                :items="units"
                                item-text="title"
                                item-value="meta.id"
                                class="pt-1"
                                :rules="[rules.required]"
                                return-object
                                label="Einsatzmittel auswählen"
                                no-data-text="Keine Einsatzmittel vorhanden"
                                outlined
                              >
                              </v-select>
                            </v-list-item>
                          </v-list>

                          <v-card-actions class="mt-n4">
                            <v-btn
                              text
                              @click="helpers.unitSelector.model = false"
                            >
                              Abbrechen
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="success"
                              text
                              :disabled="!helpers.unitSelector.isValid"
                              @click="addUnit()"
                            >
                              <v-icon left>mdi-plus</v-icon>Hinzufügen
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-list-status" title="Anwesenheiten">
              <template v-slot:actions>
                <v-btn-toggle dense v-model="helpers.displayMode" mandatory>
                  <v-btn outlined icon value="cards">
                    <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                      >mdi-view-agenda</v-icon
                    >
                  </v-btn>
                  <v-btn depressed outlined icon value="table">
                    <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                      >mdi-table</v-icon
                    >
                  </v-btn>
                </v-btn-toggle>
              </template>
            </Subheader>
            <div class="mt-7">
              <v-card v-if="helpers.displayMode === 'cards'">
                <v-card-text>
                  <v-container>
                    <v-data-iterator
                      :items="reportLocal.units.data"
                      hide-default-footer
                      disable-pagination
                      no-data-text="Keine Einsatzmittel hinzugefügt."
                    >
                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="unit in props.items"
                            :key="unit.meta.id"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-card outlined>
                              <v-card-title
                                >{{ unit.title }}<v-spacer></v-spacer
                                ><v-btn depressed @click="resetUnit(unit.id)"
                                  ><v-icon left>mdi-reload</v-icon>
                                  Zurücksetzen</v-btn
                                ></v-card-title
                              >
                              <v-card-subtitle>Besatzung: 1:5</v-card-subtitle>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" sm="12" md="4"
                                    ><v-img
                                      :src="unit.photoUrl.size400x400"
                                      contain
                                      class="mb-2"
                                    ></v-img
                                  ></v-col>
                                  <v-col cols="12" sm="12" md="8"
                                    ><v-row>
                                      <v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="Staffelführer"
                                          outlined
                                          dense
                                          clearable
                                          hide-details="auto"
                                        ></v-autocomplete
                                      ></v-col>
                                      <v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="Maschinist"
                                          outlined
                                          dense
                                          clearable
                                          hide-details="auto"
                                        ></v-autocomplete
                                      ></v-col> </v-row
                                    ><v-row
                                      ><v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="A-Truppführer"
                                          outlined
                                          dense
                                          clearable
                                          hide-details="auto"
                                        ></v-autocomplete></v-col
                                      ><v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="A-Truppmann"
                                          outlined
                                          dense
                                          clearable
                                          hide-details="auto"
                                        ></v-autocomplete
                                      ></v-col> </v-row
                                    ><v-row
                                      ><v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="W-Truppführer"
                                          outlined
                                          clearable
                                          dense
                                        ></v-autocomplete></v-col
                                      ><v-col cols="12" sm="12" md="6"
                                        ><v-autocomplete
                                          label="W-Truppmann"
                                          outlined
                                          dense
                                          clearable
                                          hide-details="auto"
                                        ></v-autocomplete
                                      ></v-col> </v-row
                                  ></v-col>
                                </v-row>
                              </v-card-text> </v-card
                          ></v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-data-table
                  v-model="helpers.attendanceSelection"
                  :headers="headers"
                  :items="reportLocal.attendances.data"
                  disable-pagination
                  item-key=""
                  hide-default-footer
                  show-select
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle">
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="error"
                          class="mr-2"
                          :disabled="!helpers.attendanceSelection.length"
                          @click="deleteAttendanceSelection"
                          ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          @click="addAttendanceEntry"
                          ><v-icon left>mdi-plus</v-icon>Hinzufügen
                        </v-btn>
                      </v-card-title>
                    </v-card>
                  </template>
                  <template v-slot:item.displayName="{ item }"
                    ><v-autocomplete
                      v-model="item.displayName"
                      outlined
                      dense
                      persistent-hint
                      hide-details="auto"
                    ></v-autocomplete
                  ></template>
                  <template v-slot:item.startDate="{ item }"
                    ><v-text-field
                      v-model="item.startDate"
                      type="date"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field
                  ></template>
                  <template v-slot:item.startTime="{ item }"
                    ><v-text-field
                      v-model="item.startTime"
                      type="time"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field
                  ></template>
                  <template v-slot:item.endDate="{ item }"
                    ><v-text-field
                      v-model="item.endDate"
                      type="date"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field
                  ></template>
                  <template v-slot:item.endTime="{ item }"
                    ><v-text-field
                      v-model="item.endTime"
                      type="time"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field
                  ></template>
                  <template v-slot:item.unit="{ item }"
                    ><v-select
                      v-model="item.unit"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select
                  ></template>
                  <template v-slot:item.action="{ item }"
                    ><v-btn
                      icon
                      text
                      color="error"
                      @click="deleteAttendanceEntry(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    ></template
                  >
                </v-data-table>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-dots-horizontal-circle"
              title="Sonstiges"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-select
                          v-model="reportLocal.reportType"
                          label="Berichtsart"
                          outlined
                          required
                          :rules="[rules.required]"
                          disabled
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-select
                          v-model="reportLocal.status"
                          :items="statusTypes"
                          label="Status"
                          outlined
                          readonly
                          hide-details="auto"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="reportLocal.notes"
                          counter
                          clearable
                          no-resize
                          label="Notizen"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <CustomDialog
      v-if="helpers.eventSelectionDialog.model"
      @close="toggleEventSelectionDialog()"
      title="Veranstaltung auswählen"
      :loading="helpers.eventSelectionDialog.loading"
      :btnAction="chooseEvent"
      :btnDisabled="!helpers.eventSelectionDialog.selectedEvent"
      :errorMessage="helpers.eventSelectionDialog.errorMessage"
      width="m"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <br />
        <!-- v-model="helpers.reportCreationDialog.formIsValid" -->
        <v-form @submit.prevent="createReport()"> test </v-form>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_UNITS } from "@/store/action-types.js";
export default {
  name: "report-editor",
  props: {
    report: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      helpers: {
        attendanceSelection: [],
        displayMode: "cards",
        unitSelector: { model: false, isValid: false, nextUnit: null },
        eventSelectionDialog: {
          model: false,
          loading: false,
          selectedEvent: null,
          errorMessage: "",
        },
      },

      headers: [
        { text: "Name", value: "displayName" },
        { text: "Startdatum", value: "startDate" },
        { text: "Startzeit", value: "startTime" },
        { text: "Enddatum", value: "endDate" },
        { text: "Endzeit", value: "endTime" },
        { text: "Einsatzmittel & Position", value: "unit" },
        { value: "action", sortable: false, width: "1%" },
      ],
      lines: [
        {
          start: "10:00",
          end: "12:00",
          displayName: "John Doe",
          unit: "LHF 3301/3",
          position: "Staffelführer",
        },
      ],
    };
  },
  model: {
    prop: "report",
    event: "reportChange",
  },
  computed: {
    reportLocal: {
      get: function () {
        return this.report;
      },
      set: function (value) {
        this.$emit("reportChange", value);
      },
    },
    units() {
      return this.$store.state.availability.units;
    },
    payrollTypes() {
      return [
        {
          id: "1",
          title: "Stundenlohn",
          disabled: false,
          formula: "{{ SUMME_ANZAHL_STUNDEN }} * 3,50",
        },
        {
          id: "2",
          title: "Pauschale",
          disabled: false,
          formula: "{{ SUMME_ANZAHL_TAGE }} * 8,00",
        },
        {
          id: "2",
          title: "Pauschale WeL",
          disabled: true,
          formula: "{{ SUMME_ANZAHL_MONAT }} * 12,00",
        },
      ];
      // return this.$store.state.accounting.payrollTypes;
    },
    reportTypes() {
      return this.$store.state.dutyManagement.reportTypes;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // Get report types
      // this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
      // Get payrolltypes
      // this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
      // Get units
      this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
      // Get memberships
    },
    toggleEventSelectionDialog() {
      this.helpers.eventSelectionDialog.model =
        !this.helpers.eventSelectionDialog.model;
    },
    addUnit() {
      this.reportLocal.units.data.push(this.helpers.unitSelector.nextUnit);
      this.helpers.unitSelector.model = false;
      this.helpers.unitSelector.nextUnit = null;
    },
    deleteUnit(index) {
      this.reportLocal.units.data.splice(index, 1);
    },
    addAttendanceEntry() {
      this.reportLocal.attendances.data.push({
        startDate: "2021-08-01",
        startTime: "10:00",
        endDate: "2021-08-01",
        endTime: "12:00",
        displayName: "John Doe",
        unit: "LHF 3301/3",
        position: "Staffelführer",
      });
    },
    deleteAttendanceEntry(item) {
      this.reportLocal.attendances.data.splice(
        this.reportLocal.attendances.data.indexOf(item),
        1
      );
    },
    deleteAttendanceSelection() {},
  },
};
</script>

<style scoped lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
