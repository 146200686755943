<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Bericht bearbeiten"
      isPreview
    ></NavigationBar>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "duty-management-reports-edit",
  components: {
    NavigationBar,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
